import actions from './actions'

const initState = { listCustomer: null, profile: null, listCountries: null, profileCustomer: null, listStatus: null }

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_LIST_CUSTOMER_SUCCESS:
      return {
        ...state,
        listCustomer: action.data,
      }
    case actions.GET_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.data,
      }
    case actions.GET_LIST_COUNTRIES_SUCCESS:
      return {
        ...state,
        listCountries: action.data,
      }
    case actions.GET_PROFILE_CUSTOMER_SUCCESS:
      return {
        ...state,
        profileCustomer: action.data,
      }
    case actions.GET_LIST_STATUS_SUCCESS:
      return {
        ...state,
        listStatus: action.data,
      }
    default:
      return state
  }
}
