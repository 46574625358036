import antdSA from 'antd/lib/locale-provider/ca_ES'
import saMessages from '../locales/es_ES.json'

const saLang = {
  messages: {
    ...saMessages,
  },
  antd: antdSA,
  locale: 'es',
}
export default saLang
