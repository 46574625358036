const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',

  GET_LIST_WALLET: 'GET_LIST_WALLET',
  GET_LIST_WALLET_SUCCESS: 'GET_LIST_WALLET_SUCCESS',

  GET_WALLET_DETAILS: 'GET_WALLET_DETAILS',

  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),

  getListWallet: (payload, cbs, cbe) => ({
    type: actions.GET_LIST_WALLET,
    payload,
    cbs,
    cbe,
  }),

  getWalletDetails: (payload, cbs, cbe) => ({
    type: actions.GET_WALLET_DETAILS,
    payload,
    cbs,
    cbe,
  }),
}
export default actions
