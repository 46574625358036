import React from 'react'
import { ConfigProvider } from 'antd'
import { IntlProvider } from 'react-intl'
import { useSelector } from 'react-redux'
// import { MoralisProvider } from 'react-moralis'
import { ThemeProvider } from 'styled-components'
import themes from '@iso/config/theme/theme.config'
import AppLocale from '@iso/config/translation'
// import { MORALIS_APP_ID, MORALIS_APP_URL } from 'config/constants'

export default function AppProvider({ children }) {
  const { locale } = useSelector((state) => state.LanguageSwitcher.language)
  const { themeName } = useSelector((state) => state.ThemeSwitcher.changeThemes)
  const currentAppLocale = AppLocale[locale]
  return (
    // <MoralisProvider serverUrl={MORALIS_APP_URL} appId={MORALIS_APP_ID}>
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
        <ThemeProvider theme={themes[themeName]}>{children}</ThemeProvider>
      </IntlProvider>
    </ConfigProvider>
    // </MoralisProvider>
  )
}
