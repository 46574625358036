import { all, call, put, takeLatest } from 'redux-saga/effects'
import fetchHelper from 'library/FetchHelper'
import { ROOT_API } from 'config/constants'
import actions from './actions'
import * as qs from 'query-string'

// Package
function getListPackagesFromApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API}/admin/package/list?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getListPackages({ payload }) {
  try {
    const { data } = yield call(getListPackagesFromApi, payload)
    if (data.code === 200) {
      yield put({
        type: actions.GET_LIST_PACKAGES_SUCCESS,
        payload: data.data,
      })
    } else {
      yield put({
        type: actions.GET_LIST_PACKAGES_SUCCESS,
        payload: null,
      })
    }
  } catch (error) {
    console.error('getListPackages', error)
  }
}

function createNewPackageFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/package/create`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* createNewPackage({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(createNewPackageFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else {
      if (cbe) cbe(data)
    }
  } catch (error) {
    if (cbe) cbe(error)
    console.error('createNewPackage', error)
  }
}

function updatePackageFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/package/update`, {
      method: 'PUT',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* updatePackage({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(updatePackageFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else {
      if (cbe) cbe(data)
    }
  } catch (error) {
    if (cbe) cbe(error)
    console.error('updatePackage', error)
  }
}

function deletePackageFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/package/delete`, {
      method: 'DELETE',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* deletePackage({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(deletePackageFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else {
      if (cbe) cbe(data)
    }
  } catch (error) {
    if (cbe) cbe(error)
    console.error('deletePackage', error)
  }
}

// Term
function getListTermsFromApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API}/admin/packageTerm/list?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getListTerms({ payload }) {
  try {
    const { data } = yield call(getListTermsFromApi, payload)
    if (data.code === 200) {
      yield put({
        type: actions.GET_LIST_TERMS_SUCCESS,
        payload: data.data,
        params: payload,
      })
    } else {
      yield put({
        type: actions.GET_LIST_TERMS_SUCCESS,
        payload: null,
        params: payload,
      })
    }
  } catch (error) {
    console.error('getListPackages', error)
  }
}

function createNewTermFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/packageTerm/create`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* createNewTerm({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(createNewTermFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else {
      if (cbe) cbe(data)
    }
  } catch (error) {
    if (cbe) cbe(error)
    console.error('createNewTerm', error)
  }
}

function updateTermFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/packageTerm/update`, {
      method: 'PUT',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* updateTerm({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(updateTermFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else {
      if (cbe) cbe(data)
    }
  } catch (error) {
    if (cbe) cbe(error)
    console.error('updateTerm', error)
  }
}

function deleteTermFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/packageTerm/delete`, {
      method: 'DELETE',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* deleteTerm({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(deleteTermFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else {
      if (cbe) cbe(data)
    }
  } catch (error) {
    if (cbe) cbe(error)
    console.error('deleteTerm', error)
  }
}

// Reward
function getListRewardFromApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API}/admin/reward/list?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getListReward({ payload }) {
  try {
    const { data } = yield call(getListRewardFromApi, payload)
    if (data.code === 200) {
      yield put({
        type: actions.GET_LIST_REWARDS_SUCCESS,
        payload: data.data,
        params: payload,
      })
    } else {
      yield put({
        type: actions.GET_LIST_REWARDS_SUCCESS,
        payload: null,
        params: payload,
      })
    }
  } catch (error) {
    console.error('getListReward', error)
  }
}

function createNewRewardFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/reward/create`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* createNewReward({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(createNewRewardFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else {
      if (cbe) cbe(data)
    }
  } catch (error) {
    if (cbe) cbe(error)
    console.error('createNewReward', error)
  }
}

function updateRewardFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/reward/update`, {
      method: 'PUT',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* updateReward({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(updateRewardFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else {
      if (cbe) cbe(data)
    }
  } catch (error) {
    if (cbe) cbe(error)
    console.error('updateReward', error)
  }
}

function deleteRewardFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/reward/delete`, {
      method: 'DELETE',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* deleteReward({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(deleteRewardFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else {
      if (cbe) cbe(data)
    }
  } catch (error) {
    if (cbe) cbe(error)
    console.error('deleteReward', error)
  }
}

// Commission
function getListCommissionFromApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API}/admin/commission/list?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getListCommission({ payload }) {
  try {
    const { data } = yield call(getListCommissionFromApi, payload)
    if (data.code === 200) {
      yield put({
        type: actions.GET_LIST_COMMISSION_SUCCESS,
        payload: data.data,
        params: payload,
      })
    } else {
      yield put({
        type: actions.GET_LIST_COMMISSION_SUCCESS,
        payload: null,
        params: payload,
      })
    }
  } catch (error) {
    console.error('getListReward', error)
  }
}

function createNewCommissionFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/commission/create`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* createNewCommission({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(createNewCommissionFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else {
      if (cbe) cbe(data)
    }
  } catch (error) {
    if (cbe) cbe(error)
    console.error('createNewCommission', error)
  }
}

function updateCommissionFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/commission/update`, {
      method: 'PUT',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* updateCommission({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(updateCommissionFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else {
      if (cbe) cbe(data)
    }
  } catch (error) {
    if (cbe) cbe(error)
    console.error('updateCommission', error)
  }
}

function deleteCommissionFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/commission/delete`, {
      method: 'DELETE',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* deleteCommission({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(deleteCommissionFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else {
      if (cbe) cbe(data)
    }
  } catch (error) {
    if (cbe) cbe(error)
    console.error('deleteCommission', error)
  }
}
export default function* rootSaga() {
  yield all([
    //
    takeLatest(actions.GET_LIST_PACKAGES, getListPackages),
    takeLatest(actions.CREATE_NEW_PACKAGE, createNewPackage),
    takeLatest(actions.UPDATE_PACKAGE, updatePackage),
    takeLatest(actions.DELETE_PACKAGE, deletePackage),
    //
    takeLatest(actions.GET_LIST_TERMS, getListTerms),
    takeLatest(actions.CREATE_NEW_TERM, createNewTerm),
    takeLatest(actions.UPDATE_TERM, updateTerm),
    takeLatest(actions.DELETE_TERM, deleteTerm),
    //
    takeLatest(actions.GET_LIST_REWARDS, getListReward),
    takeLatest(actions.CREATE_NEW_REWARD, createNewReward),
    takeLatest(actions.UPDATE_REWARD, updateReward),
    takeLatest(actions.DELETE_REWARD, deleteReward),
    //
    takeLatest(actions.GET_LIST_COMMISSION, getListCommission),
    takeLatest(actions.CREATE_NEW_COMMISSION, createNewCommission),
    takeLatest(actions.UPDATE_COMMISSION, updateCommission),
    takeLatest(actions.DELETE_COMMISSION, deleteCommission),
  ])
}
