import actions from './actions'

const initState = { idToken: null, listStatus: null }

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        idToken: action.token,
      }
    case actions.LOGOUT:
      return initState
    case actions.GET_LIST_STATUS_SUCCESS:
      return {
        ...state,
        listStatus: action.data,
      }
    default:
      return state
  }
}
