import { all, takeEvery, call, put, fork } from 'redux-saga/effects'
import fetchHelper from 'library/FetchHelper'
import { ROOT_API } from 'config/constants'
import actions from './actions'
import * as qs from 'query-string'

function getListBlockChainFromApi(payload) {
  const qsString = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API}/blockchain/list?${qsString}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getListBlockChain() {
  yield takeEvery(actions.GET_LIST_BLOCK_CHAIN, function* ({ payload, cbs, cbe }) {
    try {
      const { data } = yield call(getListBlockChainFromApi, payload, cbs, cbe)
      if (data.code === 200) {
        if (cbs) cbs(data.data)
        yield put({
          type: actions.GET_LIST_BLOCK_CHAIN_SUCCESS,
          data: data.data,
        })
      } else if (cbe) cbe(data)
    } catch (error) {
      console.error('getListBlockChain', error)
      if (cbe) cbe(error)
    }
  })
}

export default function* rootSaga() {
  yield all([fork(getListBlockChain)])
}
