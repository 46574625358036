const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  GET_LIST_BLOCK_CHAIN: 'GET_LIST_BLOCK_CHAIN',
  GET_LIST_BLOCK_CHAIN_SUCCESS: 'GET_LIST_BLOCK_CHAIN_SUCCESS',

  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),

  getListBlockChain: (payload, cbs, cbe) => ({
    type: actions.GET_LIST_BLOCK_CHAIN,
    payload,
    cbs,
    cbe,
  }),
}
export default actions
