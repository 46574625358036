import { all, call, put, takeLatest } from 'redux-saga/effects'
import fetchHelper from 'library/FetchHelper'
import { ROOT_API } from 'config/constants'
import actions from './actions'
import * as qs from 'query-string'

/** 
 // Get list game source
*/
function getListGameManagerFromApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API}/admin/gameSource/list?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getListGameSource({ payload }) {
  try {
    const { data } = yield call(getListGameManagerFromApi, payload)
    if (data.code === 200) {
      yield put({
        type: actions.GET_LIST_GAME_SOURCE_SUCCESS,
        payload: data.data,
      })
    } else {
      yield put({
        type: actions.GET_LIST_GAME_SOURCE_SUCCESS,
        payload: null,
      })
    }
  } catch (error) {
    console.error('getListGameSource', error)
  }
}

//Create Game
function createNewGameFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/gameSource/create`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* createNewGameSource({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(createNewGameFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else {
      if (cbe) cbe(data)
    }
  } catch (error) {
    if (cbe) cbe(error)
    console.error('createNewGameSource', error)
  }
}

// Delete Game
function deleteGameFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/gameSource/delete`, {
      method: 'DELETE',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* deleteGameSource({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(deleteGameFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else {
      if (cbe) cbe(data)
    }
  } catch (error) {
    if (cbe) cbe(error)
    console.error('deleteGameSource', error)
  }
}

// update Game Source
function updateGameSourceFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/gameSource/update`, {
      method: 'PUT',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* updateGameSource({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(updateGameSourceFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else {
      if (cbe) cbe(data)
    }
  } catch (error) {
    if (cbe) cbe(error)
    console.error('updateGameSource', error)
  }
}

/** 
 // Get list game type
*/
function getListGameTypeFromApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API}/admin/gameType/list?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getListGameType({ payload }) {
  try {
    const { data } = yield call(getListGameTypeFromApi, payload)
    if (data.code === 200) {
      yield put({
        type: actions.GET_LIST_GAME_TYPE_SUCCESS,
        payload: data.data,
        params: payload,
      })
    } else {
      yield put({
        type: actions.GET_LIST_GAME_TYPE_SUCCESS,
        payload: null,
        params: payload,
      })
    }
  } catch (error) {
    console.error('getListGameType', error)
  }
}

function createNewGameTypeFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/gameType/create`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* createNewGameType({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(createNewGameTypeFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else {
      if (cbe) cbe(data)
    }
  } catch (error) {
    if (cbe) cbe(error)
    console.error('createNewGameType', error)
  }
}

function updateGameTypeFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/gameType/update`, {
      method: 'PUT',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* updateGameType({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(updateGameTypeFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else {
      if (cbe) cbe(data)
    }
  } catch (error) {
    if (cbe) cbe(error)
    console.error('updateGameType', error)
  }
}

function deleteGameTypeFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/gameType/delete`, {
      method: 'DELETE',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* deleteGameType({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(deleteGameTypeFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else {
      if (cbe) cbe(data)
    }
  } catch (error) {
    if (cbe) cbe(error)
    console.error('deleteGameType', error)
  }
}

// Reward
function getListGameRewardFromApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API}/admin/gameReward/list?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getListGameReward({ payload }) {
  try {
    const { data } = yield call(getListGameRewardFromApi, payload)
    if (data.code === 200) {
      yield put({
        type: actions.GET_LIST_GAME_REWARDS_SUCCESS,
        payload: data.data,
        params: payload,
      })
    } else {
      yield put({
        type: actions.GET_LIST_GAME_REWARDS_SUCCESS,
        payload: null,
        params: payload,
      })
    }
  } catch (error) {
    console.error('getListGameReward', error)
  }
}

function createNewGameRewardFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/gameReward/create`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* createNewGameReward({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(createNewGameRewardFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else {
      if (cbe) cbe(data)
    }
  } catch (error) {
    if (cbe) cbe(error)
    console.error('createNewGameReward', error)
  }
}

function updateGameRewardFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/gameReward/update`, {
      method: 'PUT',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* updateGameReward({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(updateGameRewardFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else {
      if (cbe) cbe(data)
    }
  } catch (error) {
    if (cbe) cbe(error)
    console.error('updateGameReward', error)
  }
}

function deleteGameRewardFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/gameReward/delete`, {
      method: 'DELETE',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* deleteGameReward({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(deleteGameRewardFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else {
      if (cbe) cbe(data)
    }
  } catch (error) {
    if (cbe) cbe(error)
    console.error('deleteGameReward', error)
  }
}

// Game
function getListGameFromApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API}/admin/game/list?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getListGame({ payload }) {
  try {
    const { data } = yield call(getListGameFromApi, payload)
    if (data.code === 200) {
      yield put({
        type: actions.GET_LIST_GAME_SUCCESS,
        payload: data.data,
        params: payload,
      })
    } else {
      yield put({
        type: actions.GET_LIST_GAME_SUCCESS,
        payload: null,
        params: payload,
      })
    }
  } catch (error) {
    console.error('getListGame', error)
  }
}

function createGameFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/game/create`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* createNewGame({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(createGameFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else {
      if (cbe) cbe(data)
    }
  } catch (error) {
    if (cbe) cbe(error)
    console.error('createNewGame', error)
  }
}

function updateGameFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/game/update`, {
      method: 'PUT',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* updateGame({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(updateGameFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else {
      if (cbe) cbe(data)
    }
  } catch (error) {
    if (cbe) cbe(error)
    console.error('updateGame', error)
  }
}

function deleteGameChilFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/game/delete`, {
      method: 'DELETE',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* deleteGame({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(deleteGameChilFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else {
      if (cbe) cbe(data)
    }
  } catch (error) {
    if (cbe) cbe(error)
    console.error('deleteGame', error)
  }
}

// Commission
function getGameListCommissionFromApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API}/admin/gameCommission/list?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getGameListCommission({ payload }) {
  try {
    const { data } = yield call(getGameListCommissionFromApi, payload)
    if (data.code === 200) {
      yield put({
        type: actions.GET_GAME_LIST_COMMISSION_SUCCESS,
        payload: data.data,
        params: payload,
      })
    } else {
      yield put({
        type: actions.GET_GAME_LIST_COMMISSION_SUCCESS,
        payload: null,
        params: payload,
      })
    }
  } catch (error) {
    console.error('getGameListCommission', error)
  }
}

function createNewGameCommissionFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/gameCommission/create`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* createNewGameCommission({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(createNewGameCommissionFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else {
      if (cbe) cbe(data)
    }
  } catch (error) {
    if (cbe) cbe(error)
    console.error('createNewGameCommission', error)
  }
}

function updateGameCommissionFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/gameCommission/update`, {
      method: 'PUT',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* updateGameCommission({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(updateGameCommissionFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else {
      if (cbe) cbe(data)
    }
  } catch (error) {
    if (cbe) cbe(error)
    console.error('updateGameCommission', error)
  }
}

function deleteGameCommissionFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/gameCommission/delete`, {
      method: 'DELETE',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* deleteGameCommission({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(deleteGameCommissionFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else {
      if (cbe) cbe(data)
    }
  } catch (error) {
    if (cbe) cbe(error)
    console.error('deleteGameCommission', error)
  }
}

//create game result
function createNewGameResultFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/game/result`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* createNewGameResult({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(createNewGameResultFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
      yield put({
        type: actions.CREATE_NEW_GAME_RESULT_SUCCESS,
        payload: data.data,
        params: payload,
      })
    } else {
      yield put({
        type: actions.CREATE_NEW_GAME_RESULT_SUCCESS,
        payload: null,
        params: payload,
      })
      if (cbe) cbe(data)
    }
  } catch (error) {
    if (cbe) cbe(error)
    console.error('createNewGameResult', error)
  }
}

// review result
function getReviewResultFromApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API}/admin/game/reviewResult?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getReviewResult({ payload }) {
  try {
    const { data } = yield call(getReviewResultFromApi, payload)
    if (data.code === 200) {
      yield put({
        type: actions.REVIEW_RESULT_SUCCESS,
        payload: data.data,
        params: payload,
      })
    } else {
      yield put({
        type: actions.REVIEW_RESULT_SUCCESS,
        payload: null,
        params: payload,
      })
    }
  } catch (error) {
    console.error('getReviewResult', error)
  }
}
//send reward
function getSendRewardFromApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API}/admin/game/sendReward?${qStr}`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* sendReward({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(getSendRewardFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else {
      if (cbe) cbe(data)
    }
  } catch (error) {
    if (cbe) cbe(error)
    console.error('sendReward', error)
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_LIST_GAME_SOURCE, getListGameSource),
    takeLatest(actions.CREATE_NEW_GAME_SOURCE, createNewGameSource),
    takeLatest(actions.DELETE_GAME_SOURCE, deleteGameSource),
    takeLatest(actions.UPDATE_GAME_SOURCE, updateGameSource),

    takeLatest(actions.GET_LIST_GAME_TYPE, getListGameType),
    takeLatest(actions.CREATE_NEW_GAME_TYPE, createNewGameType),
    takeLatest(actions.UPDATE_GAME_TYPE, updateGameType),
    takeLatest(actions.DELETE_GAME_TYPE, deleteGameType),

    takeLatest(actions.GET_LIST_GAME_REWARDS, getListGameReward),
    takeLatest(actions.CREATE_NEW_GAME_REWARD, createNewGameReward),
    takeLatest(actions.UPDATE_GAME_REWARD, updateGameReward),
    takeLatest(actions.DELETE_GAME_REWARD, deleteGameReward),

    takeLatest(actions.GET_LIST_GAME, getListGame),
    takeLatest(actions.CREATE_NEW_GAME, createNewGame),
    takeLatest(actions.UPDATE_GAME, updateGame),
    takeLatest(actions.DELETE_GAME, deleteGame),
    //
    takeLatest(actions.GET_GAME_LIST_COMMISSION, getGameListCommission),
    takeLatest(actions.CREATE_NEW_GAME_COMMISSION, createNewGameCommission),
    takeLatest(actions.UPDATE_GAME_COMMISSION, updateGameCommission),
    takeLatest(actions.DELETE_GAME_COMMISSION, deleteGameCommission),

    takeLatest(actions.CREATE_NEW_GAME_RESULT, createNewGameResult),
    takeLatest(actions.REVIEW_RESULT, getReviewResult),
    takeLatest(actions.SEND_REWARD, sendReward),
  ])
}
