import actions from './actions'

const initState = { listTransaction: null }

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_LIST_TRANSACTION_SUCCESS:
      return {
        ...state,
        listTransaction: action.data,
      }
    default:
      return state
  }
}
