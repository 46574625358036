const actions = {
  GET_LIST_PACKAGES: 'GET_LIST_PACKAGES',
  GET_LIST_PACKAGES_SUCCESS: 'GET_LIST_PACKAGES_SUCCESS',

  CREATE_NEW_PACKAGE: 'CREATE_NEW_PACKAGE',
  UPDATE_PACKAGE: 'UPDATE_PACKAGE',
  DELETE_PACKAGE: 'DELETE_PACKAGE',

  GET_LIST_TERMS: 'GET_LIST_TERMS',
  GET_LIST_TERMS_SUCCESS: 'GET_LIST_TERMS_SUCCESS',
  CREATE_NEW_TERM: 'CREATE_NEW_TERM',
  UPDATE_TERM: 'UPDATE_TERM',
  DELETE_TERM: 'DELETE_TERM',

  GET_LIST_REWARDS: 'GET_LIST_REWARDS',
  GET_LIST_REWARDS_SUCCESS: 'GET_LIST_REWARDS_SUCCESS',
  CREATE_NEW_REWARD: 'CREATE_NEW_REWARD',
  UPDATE_REWARD: 'UPDATE_REWARD',
  DELETE_REWARD: 'DELETE_REWARD',

  GET_LIST_COMMISSION: 'GET_LIST_COMMISSION',
  GET_LIST_COMMISSION_SUCCESS: 'GET_LIST_COMMISSION_SUCCESS',
  CREATE_NEW_COMMISSION: 'CREATE_NEW_COMMISSION',
  UPDATE_COMMISSION: 'UPDATE_COMMISSION',
  DELETE_COMMISSION: 'DELETE_COMMISSION',

  //
  getListPackages: (payload) => ({
    type: actions.GET_LIST_PACKAGES,
    payload,
  }),
  createNewPackage: (payload, cbs, cbe) => ({
    type: actions.CREATE_NEW_PACKAGE,
    payload,
    cbs,
    cbe,
  }),
  updatePackage: (payload, cbs, cbe) => ({
    type: actions.UPDATE_PACKAGE,
    payload,
    cbs,
    cbe,
  }),
  deletePackage: (payload, cbs, cbe) => ({
    type: actions.DELETE_PACKAGE,
    payload,
    cbs,
    cbe,
  }),

  //
  getListTerms: (payload) => ({
    type: actions.GET_LIST_TERMS,
    payload,
  }),
  createNewTerm: (payload, cbs, cbe) => ({
    type: actions.CREATE_NEW_TERM,
    payload,
    cbs,
    cbe,
  }),
  updateTerm: (payload, cbs, cbe) => ({
    type: actions.UPDATE_TERM,
    payload,
    cbs,
    cbe,
  }),
  deleteTerm: (payload, cbs, cbe) => ({
    type: actions.DELETE_TERM,
    payload,
    cbs,
    cbe,
  }),

  //
  getListReward: (payload) => ({
    type: actions.GET_LIST_REWARDS,
    payload,
  }),
  createNewReward: (payload, cbs, cbe) => ({
    type: actions.CREATE_NEW_REWARD,
    payload,
    cbs,
    cbe,
  }),
  updateReward: (payload, cbs, cbe) => ({
    type: actions.UPDATE_REWARD,
    payload,
    cbs,
    cbe,
  }),
  deleteReward: (payload, cbs, cbe) => ({
    type: actions.DELETE_REWARD,
    payload,
    cbs,
    cbe,
  }),

  //
  getListCommission: (payload) => ({
    type: actions.GET_LIST_COMMISSION,
    payload,
  }),
  createNewCommission: (payload, cbs, cbe) => ({
    type: actions.CREATE_NEW_COMMISSION,
    payload,
    cbs,
    cbe,
  }),
  updateCommission: (payload, cbs, cbe) => ({
    type: actions.UPDATE_COMMISSION,
    payload,
    cbs,
    cbe,
  }),
  deleteCommission: (payload, cbs, cbe) => ({
    type: actions.DELETE_COMMISSION,
    payload,
    cbs,
    cbe,
  }),
}
export default actions
