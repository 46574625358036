import actions from './actions'

const initState = { stakingList: undefined, termsList: {}, rewardList: {}, commissionList: {} }

export default function stakingReducer(state = initState, action) {
  const { type, payload } = action
  switch (type) {
    case actions.GET_LIST_PACKAGES_SUCCESS:
      return {
        ...state,
        stakingList: payload,
      }
    case actions.GET_LIST_TERMS_SUCCESS:
      return {
        ...state,
        termsList: payload,
      }
    case actions.GET_LIST_REWARDS_SUCCESS:
      return {
        ...state,
        rewardList: payload,
      }

    case actions.GET_LIST_COMMISSION_SUCCESS:
      return {
        ...state,
        commissionList: payload,
      }
    default:
      return state
  }
}
