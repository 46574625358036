import React, { lazy, Suspense } from 'react'
import { Route, Redirect, BrowserRouter as Router, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'

import ErrorBoundary from './ErrorBoundary'
import { PUBLIC_ROUTE } from './route.constants'
import Loader from '@iso/components/utility/loader'

const RouterPrivate = lazy(() => import('./router.private'))

const publicRoutes = [
  // {
  //   path: PUBLIC_ROUTE.LANDING,
  //   exact: true,
  //   component: lazy(() => import('@iso/modules/Auth/SignIn/SignIn')),
  // },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() => import('@iso/modules/Auth/SignIn/SignIn')),
  },
  // {
  //   path: PUBLIC_ROUTE.SIGN_UP,
  //   component: lazy(() => import('@iso/modules/Auth/SignUp/SignUp')),
  // },
  // {
  //   path: PUBLIC_ROUTE.FORGET_PASSWORD,
  //   component: lazy(() => import('@iso/modules/Auth/ForgotPassword/ForgotPassword')),
  // },
  // {
  //   path: PUBLIC_ROUTE.RESET_PASSWORD,
  //   component: lazy(() => import('@iso/modules/Auth/ResetPassword/ResetPassword')),
  // },
  {
    path: PUBLIC_ROUTE.PAGE_404,
    component: lazy(() => import('@iso/modules/Auth/404/404')),
  },
  {
    path: PUBLIC_ROUTE.PAGE_500,
    component: lazy(() => import('@iso/modules/Auth/500/500')),
  },
]

function PublicRoute({ children, ...rest }) {
  const isLoggedIn = useSelector((state) => state.Auth.idToken)
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/report/customer',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}
function PrivateRoute({ children, ...rest }) {
  const isLoggedIn = useSelector((state) => state.Auth.idToken)
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

export default function Routes() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            {publicRoutes.map((route, index) => (
              <PublicRoute key={index} path={route.path} exact={route.exact}>
                <route.component />
              </PublicRoute>
            ))}
            <PrivateRoute path="/">
              <RouterPrivate />
            </PrivateRoute>
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  )
}
