import { all, takeEvery, call, put, fork } from 'redux-saga/effects'
import fetchHelper from 'library/FetchHelper'
import { ROOT_API } from 'config/constants'
import actions from './actions'
import * as qs from 'query-string'

function getListUserFromApi(payload) {
  const qsString = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API}/admin/user/list?${qsString}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getListUser() {
  yield takeEvery(actions.GET_LIST_USER, function* ({ payload, cbs, cbe }) {
    try {
      const { data } = yield call(getListUserFromApi, payload, cbs, cbe)
      if (data.code === 200) {
        if (cbs) cbs(data.data)
        yield put({
          type: actions.GET_LIST_USER_SUCCESS,
          data: data.data,
        })
      } else if (cbe) cbe(data)
    } catch (error) {
      console.error('getListUser', error)
      if (cbe) cbe(error)
    }
  })
}

function createUserFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/user/create`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* createUser() {
  yield takeEvery(actions.CREATE_USER, function* ({ payload, cbs, cbe }) {
    try {
      const { data } = yield call(createUserFromApi, payload, cbs, cbe)
      if (data.code === 200) {
        if (cbs) cbs(data.data)
      } else if (cbe) cbe(data)
    } catch (error) {
      console.error('createUser', error)
      if (cbe) cbe(error)
    }
  })
}

function getProfileFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/user/profile`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getProfile() {
  yield takeEvery(actions.GET_PROFILE, function* ({ payload, cbs, cbe }) {
    try {
      const { data } = yield call(getProfileFromApi, payload, cbs, cbe)
      if (data.code === 200) {
        if (cbs) cbs(data.data)
        yield put({
          type: actions.GET_PROFILE_SUCCESS,
          data: data.data,
        })
      } else if (cbe) cbe(data)
    } catch (error) {
      console.error('getProfile', error)
      if (cbe) cbe(error)
    }
  })
}

export default function* rootSaga() {
  yield all([fork(getListUser), fork(createUser), fork(getProfile)])
}
