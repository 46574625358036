const actions = {
  GET_LIST_GAME_SOURCE: 'GET_LIST_GAME_SOURCE',
  GET_LIST_GAME_SOURCE_SUCCESS: 'GET_LIST_GAME_SOURCE_SUCCESS',
  CREATE_NEW_GAME_SOURCE: 'CREATE_NEW_GAME_SOURCE',
  DELETE_GAME_SOURCE: 'DELETE_GAME_SOURCE',
  UPDATE_GAME_SOURCE: 'UPDATE_GAME_SOURCE',

  GET_LIST_GAME_TYPE: 'GET_LIST_GAME_TYPE',
  GET_LIST_GAME_TYPE_SUCCESS: 'GET_LIST_GAME_TYPE_SUCCESS',
  CREATE_NEW_GAME_TYPE: 'CREATE_NEW_GAME_TYPE',
  UPDATE_GAME_TYPE: 'UPDATE_GAME_TYPE',
  DELETE_GAME_TYPE: 'DELETE_GAME_TYPE',

  GET_LIST_GAME_REWARDS: 'GET_LIST_GAME_REWARDS',
  GET_LIST_GAME_REWARDS_SUCCESS: 'GET_LIST_GAME_REWARDS_SUCCESS',
  CREATE_NEW_GAME_REWARD: 'CREATE_NEW_GAME_REWARD',
  UPDATE_GAME_REWARD: 'UPDATE_GAME_REWARD',
  DELETE_GAME_REWARD: 'DELETE_GAME_REWARD',

  GET_LIST_GAME: 'GET_LIST_GAME',
  GET_LIST_GAME_SUCCESS: 'GET_LIST_GAME_SUCCESS',
  CREATE_NEW_GAME: 'CREATE_NEW_GAME',
  UPDATE_GAME: 'UPDATE_GAME',
  DELETE_GAME: 'DELETE_GAME',

  GET_GAME_LIST_COMMISSION: 'GET_GAME_LIST_COMMISSION',
  GET_GAME_LIST_COMMISSION_SUCCESS: 'GET_GAME_LIST_COMMISSION_SUCCESS',
  CREATE_NEW_GAME_COMMISSION: 'CREATE_NEW_GAME_COMMISSION',
  UPDATE_GAME_COMMISSION: 'UPDATE_GAME_COMMISSION',
  DELETE_GAME_COMMISSION: 'DELETE_GAME_COMMISSION',

  CREATE_NEW_GAME_RESULT: 'CREATE_NEW_GAME_RESULT',
  CREATE_NEW_GAME_RESULT_SUCCESS: 'CREATE_NEW_GAME_RESULT_SUCCESS',
  REVIEW_RESULT: 'REVIEW_RESULT',
  REVIEW_RESULT_SUCCESS: 'REVIEW_RESULT_SUCCESS',
  SEND_REWARD: 'SEND_REWARD',
  SEND_REWARD_SUCCESS: 'SEND_REWARD_SUCCESS',

  // game source action
  getListGameSource: (payload) => ({
    type: actions.GET_LIST_GAME_SOURCE,
    payload,
  }),
  getListGameSourceSuccess: (payload, cbs, cbe) => ({
    type: actions.GET_LIST_GAME_SOURCE_SUCCESS,
    payload,
    cbs,
    cbe,
  }),
  createNewGameSource: (payload, cbs, cbe) => ({
    type: actions.CREATE_NEW_GAME_SOURCE,
    payload,
    cbs,
    cbe,
  }),
  deleteGameSource: (payload, cbs, cbe) => ({
    type: actions.DELETE_GAME_SOURCE,
    payload,
    cbs,
    cbe,
  }),
  updateGameSource: (payload, cbs, cbe) => ({
    type: actions.UPDATE_GAME_SOURCE,
    payload,
    cbs,
    cbe,
  }),

  // game type action
  getListGameType: (payload) => ({
    type: actions.GET_LIST_GAME_TYPE,
    payload,
  }),
  createNewGameType: (payload, cbs, cbe) => ({
    type: actions.CREATE_NEW_GAME_TYPE,
    payload,
    cbs,
    cbe,
  }),
  updateGameType: (payload, cbs, cbe) => ({
    type: actions.UPDATE_GAME_TYPE,
    payload,
    cbs,
    cbe,
  }),
  deleteGameType: (payload, cbs, cbe) => ({
    type: actions.DELETE_GAME_TYPE,
    payload,
    cbs,
    cbe,
  }),

  //
  getListGameReward: (payload) => ({
    type: actions.GET_LIST_GAME_REWARDS,
    payload,
  }),
  createNewGameReward: (payload, cbs, cbe) => ({
    type: actions.CREATE_NEW_GAME_REWARD,
    payload,
    cbs,
    cbe,
  }),
  updateGameReward: (payload, cbs, cbe) => ({
    type: actions.UPDATE_GAME_REWARD,
    payload,
    cbs,
    cbe,
  }),
  deleteGameReward: (payload, cbs, cbe) => ({
    type: actions.DELETE_GAME_REWARD,
    payload,
    cbs,
    cbe,
  }),

  //
  getListGame: (payload) => ({
    type: actions.GET_LIST_GAME,
    payload,
  }),
  createNewGame: (payload, cbs, cbe) => ({
    type: actions.CREATE_NEW_GAME,
    payload,
    cbs,
    cbe,
  }),
  updateGame: (payload, cbs, cbe) => ({
    type: actions.UPDATE_GAME,
    payload,
    cbs,
    cbe,
  }),
  deleteGame: (payload, cbs, cbe) => ({
    type: actions.DELETE_GAME,
    payload,
    cbs,
    cbe,
  }),

  //
  getGameListCommission: (payload) => ({
    type: actions.GET_GAME_LIST_COMMISSION,
    payload,
  }),
  createNewGameCommission: (payload, cbs, cbe) => ({
    type: actions.CREATE_NEW_GAME_COMMISSION,
    payload,
    cbs,
    cbe,
  }),
  updateGameCommission: (payload, cbs, cbe) => ({
    type: actions.UPDATE_GAME_COMMISSION,
    payload,
    cbs,
    cbe,
  }),
  deleteGameCommission: (payload, cbs, cbe) => ({
    type: actions.DELETE_GAME_COMMISSION,
    payload,
    cbs,
    cbe,
  }),

  createNewGameResult: (payload, cbs, cbe) => ({
    type: actions.CREATE_NEW_GAME_RESULT,
    payload,
    cbs,
    cbe,
  }),

  getReviewResult: (payload) => ({
    type: actions.REVIEW_RESULT,
    payload,
  }),

  sendReward: (payload, cbs, cbe) => ({
    type: actions.SEND_REWARD,
    payload,
    cbs,
    cbe,
  }),
}
export default actions
