import { all, takeEvery, call, put, fork } from 'redux-saga/effects'
import fetchHelper from 'library/FetchHelper'
import { ROOT_API } from 'config/constants'
import actions from './actions'
import * as qs from 'query-string'

function getListWalletFromApi(payload) {
  const qsString = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API}/admin/wallet/list?${qsString}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getListWallet() {
  yield takeEvery(actions.GET_LIST_WALLET, function* ({ payload, cbs, cbe }) {
    try {
      const { data } = yield call(getListWalletFromApi, payload, cbs, cbe)
      if (data.code === 200) {
        if (cbs) cbs(data.data)
        yield put({
          type: actions.GET_LIST_WALLET_SUCCESS,
          data: data.data,
        })
      } else if (cbe) cbe(data)
    } catch (error) {
      console.error('getListWallet', error)
      if (cbe) cbe(error)
    }
  })
}

function getWalletDetailsFromApi(payload) {
  const qsString = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API}/admin/wallet/detail?${qsString}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getWalletDetails() {
  yield takeEvery(actions.GET_WALLET_DETAILS, function* ({ payload, cbs, cbe }) {
    try {
      const { data } = yield call(getWalletDetailsFromApi, payload, cbs, cbe)
      if (data.code === 200) {
        if (cbs) cbs(data.data)
        return data.data
      } else if (cbe) cbe(data)
    } catch (error) {
      console.error('getWalletDetails', error)
      if (cbe) cbe(error)
    }
  })
}

export default function* rootSaga() {
  yield all([
    // fork(getListWallet),
    fork(getListWallet),
    fork(getWalletDetails),
  ])
}
