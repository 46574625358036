import { all, call, put, takeLatest } from 'redux-saga/effects'
import profileActions from './actions'
import DemoProfileData from './profile.data'

function getProfileFromApi() {
  return { data: { code: 200, data: DemoProfileData } }
  // return fetchHelper
  //   .fetch(`${ROOT_API}/customer/profile`, {
  //     method: 'GET',
  //   })
  //   .then(([resp, status]) => ({
  //     data: resp,
  //     status,
  //   }))
}
function* getProfile() {
  try {
    const { data } = yield call(getProfileFromApi)
    if (data.code === 200) {
      yield put(profileActions.getProfileSuccess(data.data))
    }
  } catch (error) {
    console.error('getProfile', error)
  }
}

export default function* rootSaga() {
  yield all([takeLatest(profileActions.GET_PROFILE, getProfile)])
}
