const profileActions = {
  GET_PROFILE: 'GET_PROFILE',
  GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
  getProfile: () => ({
    type: profileActions.GET_PROFILE,
  }),
  getProfileSuccess: (payload) => ({
    type: profileActions.GET_PROFILE_SUCCESS,
    payload,
  }),
}

export default profileActions
