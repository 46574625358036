import actions from './actions'

const initState = { idToken: null, listUser: null, profile: null }

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_LIST_USER_SUCCESS:
      return {
        ...state,
        listUser: action.data,
      }
    case actions.GET_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.data,
      }
    default:
      return state
  }
}
