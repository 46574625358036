import actions from './actions'

const initState = {
  gameSource: undefined,
  gameTypeList: undefined,
  gameRewardList: undefined,
  game: undefined,
  gameCommissionList: undefined,
  reviewResult: undefined,
}

export default function stakingReducer(state = initState, action) {
  const { type, payload } = action
  switch (type) {
    case actions.GET_LIST_GAME_SOURCE_SUCCESS:
      return {
        ...state,
        gameSource: payload,
      }
    case actions.GET_LIST_GAME_TYPE_SUCCESS:
      return {
        ...state,
        gameTypeList: payload,
      }
    case actions.GET_LIST_GAME_REWARDS_SUCCESS:
      return {
        ...state,
        gameRewardList: payload,
      }
    case actions.GET_LIST_GAME_SUCCESS:
      return {
        ...state,
        game: payload,
      }
    case actions.GET_GAME_LIST_COMMISSION_SUCCESS:
      return {
        ...state,
        gameCommissionList: payload,
      }
    case actions.REVIEW_RESULT_SUCCESS:
      return {
        ...state,
        reviewResult: payload,
      }
    default:
      return state
  }
}
