import { all, takeEvery, call, put, fork } from 'redux-saga/effects'
import fetchHelper, { deleteAuthToken, getAuthToken, setAuthToken } from 'library/FetchHelper'
import { CACHE_TOKEN, ROOT_API } from 'config/constants'
import actions from './actions'
import profileActions from '../profile/actions'
import * as qs from 'query-string'

function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
    yield setAuthToken(payload.token)
  })
}
function loginFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API}/admin/user/login`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* loginRequest() {
  yield takeEvery(actions.LOGIN_REQUEST, function* ({ payload, cbs, cbe }) {
    try {
      const { data } = yield call(loginFromApi, payload, cbs, cbe)
      if (data.code === 200) {
        if (cbs) cbs(data.data)
        yield put({
          type: actions.LOGIN_SUCCESS,
          token: data.data.token,
        })
      } else if (cbe) cbe(data)
    } catch (error) {
      console.error('loginRequest', error)
      if (cbe) cbe(error)
    }
  })
}

function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    yield deleteAuthToken()
  })
}
function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const token = getAuthToken().get(CACHE_TOKEN)
    if (token) {
      yield put({
        type: profileActions.GET_PROFILE,
        token,
      })
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
      })
    }
  })
}

function getListStatusFromApi(payload) {
  const qsString = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API}/staticData/data?${qsString}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getListStatus() {
  yield takeEvery(actions.GET_LIST_STATUS, function* ({ payload, cbs, cbe }) {
    try {
      const { data } = yield call(getListStatusFromApi, payload, cbs, cbe)
      if (data.code === 200) {
        if (cbs) cbs(data.data)
        yield put({
          type: actions.GET_LIST_STATUS_SUCCESS,
          data: data.data,
        })
      } else if (cbe) cbe(data)
    } catch (error) {
      console.error('getListStatus', error)
      if (cbe) cbe(error)
    }
  })
}

export default function* rootSaga() {
  yield all([fork(checkAuthorization), fork(loginRequest), fork(loginSuccess), fork(logout), fork(getListStatus)])
}
